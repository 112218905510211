import React from "react";
import "./App.css";
const API_URL = "https://u2k6fzfuk3xmx7i3ogednebr2m0mpuxv.lambda-url.us-east-1.on.aws";

class CheckoutWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: "",
      error: false
    };
  }
      
  componentDidMount(){
    const query = new URLSearchParams(window.location.search);
    this.toggleLoad = this.setState({loading: !this.state.loading})
    this.setState({
      toggleLoading: ()=>this.setState({loading: !this.state.loading}),
      setError: (message)=>this.setState({error: message}),
    })
    if (query.get("success")) {
      this.setState({message: "Order placed! Thank you for your interest 😉."});
    }
      
    if (query.get("canceled")) {
      this.setState({message: "Order canceled -- Feel free to reach out to me when ready: gregor@gregorrichardson.com."});
    }
  }
  
  handleSubmit = async (event) => {
    event.preventDefault();
    console.log('changing loading status in handleSubmit')
    this.state.setError(false);
    this.state.toggleLoading();
    fetch(API_URL, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type" : "application/json",
      },
      body: JSON.stringify("")
    }).then(response => { console.log('I got a response', response); return response.json();})
    .then(response => {
      console.log('backend response', response)
      if (response.message) {
        this.resetForm();
      }
      if (response.url) {
        console.log('Redirecting page to '+ response.url);
        window.location.href = response.url;
      } else if (response.err) {
        console.error("Stripe error",response.err.raw.message);
        this.state.setError(response.err.raw.message);
      }
    }).catch(error => {
      console.error('error...', error);
      this.state.setError("An unknown error has occurred");
    });
    this.state.toggleLoading();
    console.log('loading status should change to false');
  };
  
  Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );

render() {
  return (
    <>
    {this.state.message ? document.querySelector('#checkout_modal').showModal() : null}
      <section>
        {console.log('loader status in ProductDisplay', this.state.loading)}
        <div className="tooltip tooltip-bottom" data-tip="Captain Gregor Richardson, at your service.">
          <div className="product">
          <img
            src="/captain.png"
            alt="Gregor Richardson - Solutions Architect"
          />
        </div>
          <div className="description">
          </div>
        </div>
        <div  className="button btn btn-purple" onClick={(e) => {this.handleSubmit(e) }} disabled={false}>
          Add Gregor to your <span className="line">cart</span> team
        </div>
        {this.state.loading ? <div className="loading loading-spinner loading-md m-auto block"></div> : null}
      </section>
    </>
  )
  }
}
export default CheckoutWidget;
